import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"

const contact = graphql`
  query {
    contact: contentfulContactPage {
      title
      text {
        text
      }
      email
    }
  }
`

const Contact = ({ location }) => {
  const response = useStaticQuery(contact)

  return (
    <Layout location={location}>
      <ContactContainer>
        <PageTitle>{response.contact.title}</PageTitle>
        <Description>{response.contact.text.text}</Description>
        <FormWrapper>
          <form
            action={`https://formspree.io/${response.contact.email}`}
            method="POST"
            className="form"
          >
            <input
              type="text"
              name="name"
              id="name"
              className="name"
              placeholder="Name"
              required
            />
            <input
              type="email"
              name="email"
              id="email"
              className="email"
              placeholder="Email"
              required
            />
            <textarea
              name="message"
              id="message"
              rows="10"
              className="message"
              placeholder="Message"
              required
            />
            <input type="submit" value="Send" className="submit" />
          </form>
        </FormWrapper>
      </ContactContainer>
    </Layout>
  )
}

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
`

const PageTitle = styled.h1`
  font-family: "Mukta", sans-serif;
  font-weight: 400;
  font-size: 3.5vh;
  text-transform: uppercase;
  margin: 3vh 0;
`

const Description = styled.div`
  max-width: 500px;
  width: 90%;
  font-size: 1.5vh;
  font-weight: 300;
  margin: 5vh;
`

const FormWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20vh;
  padding: 0;

  form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input,
    textarea {
      width: 100%;
      max-width: 100%;
      margin: 0.6rem auto;
      padding: 0.5rem 1rem;
      background: #fff;
      border: black 1px solid;
      border-radius: 0.4rem;
      color: grey;
      font-family: "Helvetica", sans-serif;
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 2px;
    }
    input {
      height: 2.5rem;
      &[type="submit"] {
        width: 50%;
        max-width: 200px;
        background: black;
        color: whitesmoke;
        transition: all 0.5s ease;
        cursor: pointer;
        -webkit-appearance: none;
        &:hover {
          background: whitesmoke;
          color: darkgrey;
        }
      }
    }
    textarea {
      height: 15rem;
    }
  }
`

export default Contact
